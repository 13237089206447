import { Box, Flex, FormErrorMessage } from "@chakra-ui/react"

interface Props {
  error?: string | { message?: string | undefined; types?: any }
}

export function InputError(props: Props) {
  if (!props.error) return null
  return (
    <>
      {typeof props.error === "string" ? (
        <FormErrorMessage>{props.error}</FormErrorMessage>
      ) : props.error.message && typeof props.error.message === "string" ? (
        <FormErrorMessage>{props.error.message}</FormErrorMessage>
      ) : (
        props.error.types &&
        Object.values(props.error.types).map((error, i) => (
          <FormErrorMessage key={i}>{error as string}</FormErrorMessage>
        ))
      )}
    </>
  )
}
export const InlineInputError: React.FC<Props> = (props) => {
  if (!props.error) return null
  return (
    <Flex justify="flex-end">
      <Box>
        {typeof props.error === "string" ? (
          <FormErrorMessage fontSize="xs">{props.error}</FormErrorMessage>
        ) : props.error.message ? (
          <FormErrorMessage fontSize="xs">{props.error.message}</FormErrorMessage>
        ) : (
          props.error.types &&
          Object.values(props.error.types).map((error, i) => (
            <FormErrorMessage key={i} fontSize="xs">
              {error as string}
            </FormErrorMessage>
          ))
        )}
      </Box>
    </Flex>
  )
}
